<template>
  <div id="home">
    <div class="top">
      <div class="sidebar">
        <el-scrollbar style="height: 100%; width: 200px" ref="sub">
          <ul class="menu">
            <li
              v-for="(navList, index) in Classification"
              :key="index"
              class="forRealitive"
              @mouseover="showToggle(index)"
              @mouseout="handleHide"
            >
              <p class="sidebar-link" @click="goCategory(navList)">
                <img v-lazy="navList.categoryImage" /><a>{{
                  navList.categoryName
                }}</a>
                <svg-icon icon-class="ic-arrow-b" class="ic-arrow" />
              </p>

              <ul class="menu-sub" v-show="index === isShow">
                <li v-for="(item, idx) in navList.subItemList" :key="idx">
                  <p
                    class="menu-title"
                    :title="item.categoryName"
                    @click="goCategory(item)"
                  >
                    {{ item.categoryName }}<span>&gt;</span>
                  </p>

                  <div class="three-menu">
                    <a
                      class="section-link"
                      @click="goCategory(list)"
                      v-for="(list, i) in item.subItemList"
                      :key="i"
                      :title="list.categoryName"
                      >{{ list.categoryName }}
                    </a>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </el-scrollbar>
      </div>
      <div class="banner">
        <el-carousel :interval="6000" arrow="always" height="360px">
          <el-carousel-item v-for="(list, i) in Banner" :key="i">
            <img v-lazy="list.picPosition" alt="轮播图" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="user">
        <div class="user-box">
          <img
            :src="
              userInfo.userDetailEntity.profilePicture === null
                ? require('@/assets/images/user.png')
                : userInfo.userDetailEntity.profilePicture
            "
            alt="用户头像"
          />
          <p v-if="userLogin">
            Hi，{{
              userInfo.userEntity.name != null
                ? userInfo.userEntity.name
                : userInfo.userEntity.telNum
            }}
          </p>
          <p v-else>Hi，{{ userData.userEntity.name || "您好" }}</p>

          <div v-if="userLogin">
            <a href="/#/PersonalCenter/AllOrders">个人中心</a>
            <a @click="logOut" style="cursor: pointer">退出登录</a>
          </div>

          <div v-else-if="isId && isLogin">
            <a style="width: 120px" :href="$URL">请完善公司资料</a>
          </div>
          <div v-else-if="isLogin">
            <a style="width: 100px" :href="$URL">前往管理中心</a>
          </div>
          <div v-else>
            <a :href="$URL">登录</a>
            <a :href="$URL">注册</a>
          </div>
        </div>
        <div class="news">
          <div class="title">
            <p>新闻资讯</p>
            <router-link to="/News">More>></router-link>
          </div>
          <div class="content">
            <ul v-if="!none">
              <li v-for="(list, index) in News" :key="index">
                <i></i
                ><span
                  @click="goNewsDetails(list.newsCode)"
                  :title="list.newsTitle"
                  >{{ list.newsTitle }}</span
                >
              </li>
            </ul>
            <!-- <div>
              <img src="@/assets/images/none.png" alt="">
              <p>暂时没有新闻资讯哦~</p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="activity">
      <div class="left">
        <p>活动专栏</p>
        <p>限时优惠</p>
        <router-link to="/AllActivities">查看更多>></router-link>
      </div>
      <div class="right">
        <div
          v-for="(list, index) in Activity"
          :key="index"
          @click="goActivitiesColumn(list.id)"
        >
          <img :src="ActivityPic[index].pic[0]" :alt="list.activityName" />
          <!-- <img src="" alt="" />
          <p></p> -->
        </div>
      </div>
    </div>
    <div class="recommended-brand">
      <div class="title">
        <div class="left">
          <img
            src="@/assets/images/RecommendedBrand.png"
            alt="韶关全球商品交易平台推荐品牌"
          />
          <h2>推荐品牌</h2>
        </div>
        <div class="right">
          <router-link to="/AllBrand"
            >全部品牌<svg-icon icon-class="ic-arrow-d"
          /></router-link>
        </div>
      </div>
      <div class="content">
        <div
          class="brand"
          v-for="(list, index) in RecommendedBrand"
          :key="index"
          @mouseover="MouseoverBrand(index)"
          @mouseout="BrandShow = -1"
          @click="goBrand(list.id, list.brandId, list.brandName)"
        >
          <img :src="list.logo" :alt="list.brandName" />
          <p v-show="index === BrandShow">{{ list.brandName }}</p>
        </div>
        <el-empty
          description="暂无品牌"
          v-show="RecommendedBrand.length === 0"
          :image-size="100"
        ></el-empty>
      </div>
    </div>
    <!-- <div class="recommend-goods">
      <div class="title">
        <div class="left">
          <img src="@/assets/images/recommended.png" alt="" />
          <h2>推荐商品</h2>
        </div>
        <div class="right">
          <router-link to="/Brand"
            >全部商品<svg-icon icon-class="ic-arrow-d"
          /></router-link>
        </div>
      </div>
    </div> -->
    <recommended :goods="OrdinaryGoods" />
    <div class="recommend-store">
      <div class="title">
        <div class="left">
          <img
            src="@/assets/images/recommend-store.png"
            alt="韶关全球商品交易平台推荐店铺"
          />
          <h2>推荐店铺</h2>
        </div>
        <div class="right">
          <router-link to="StoreList"
            >全部店铺<svg-icon icon-class="ic-arrow-d"
          /></router-link>
        </div>
      </div>
      <div class="content">
        <div
          class="store"
          v-for="(list, index) in StoreLIst"
          :key="index"
          @click="goStore(list.storeId, list.enterpriseId)"
        >
          <img v-lazy="list.storeLogo" :key="index" />
          <div>
            <h3>{{ list.storeName }}</h3>
            <p :title="list.storeIntroduce">{{ list.storeIntroduce }}</p>
          </div>
        </div>
        <el-empty
          description="暂无店铺"
          v-show="StoreLIst.length === 0"
          :image-size="100"
        ></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import Recommended from "@/components/Recommended";
import { mapState } from "vuex";
import Cookies from "js-cookie";

export default {
  name: "home",
  components: {
    Recommended,
  },
  computed: {
    ...mapState("user", ["isLogin", "userData", "id"]),
    ...mapState("personalUser", ["userLogin", "userId", "userInfo"]),
  },
  watch: {
    id: {
      //监听store/user 里的isLogin有没改变
      handler(newValue) {
        if (newValue) {
          this.isId = false;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      isShow: 1,
      BrandShow: -1,
      Classification: "", //分类信息
      OrdinaryGoods: "", //推荐商品信息
      ItemCode: "", //推荐商品单品编码
      StoreLIst: "", //推荐店铺信息
      RecommendedBrand: "", //推荐品牌信息
      Banner: "", //轮播图
      Activity: "", //活动
      News: "", //新闻
      ActivityPic: "", //活动图片
      Id: "",
      isId: true,
      none: false,
    };
  },
  created() {
    process.env.NODE_ENV === "development" &&
      (this.URL = "http://192.168.1.178:8080/#/PersonalCenter");
    process.env.NODE_ENV === "test" &&
      (this.URL = "http://trades.realbrand.net/#/PersonalCenter");
    process.env.NODE_ENV === "production" &&
      (this.URL = "http://shaoguan.qqspjypt.com/#/PersonalCenter");

    this.getCommList();
    this.getQueryBrandList();
    this.getStoreLIst();
    this.getClassification();
    this.getBanner();
    this.getActivityList();
    this.getNews();
  },
  mounted() {},
  methods: {
    //首页推荐普通商品
    async getCommList() {
      const data = await this.$API.homePage.CommList({
        object: {
          brandId: "",
          ceCategoryId: null,
          location: "4",
          order: "",
          sortColumn: "",
        },
        pageNum: 1,
        pageSize: 15,
      });
      this.OrdinaryGoods = data.data.data.map((a) => {
        return {
          ...a,
          filePath: a.filePath.split(",")[0],
        };
      });
      // console.log(data.data.data, "推荐商品");
    },
    //首页推荐品牌
    async getQueryBrandList() {
      const data = await this.$API.homePage.QueryBrandList({
        object: {
          location: "2",
        },
        pageNum: 1,
        pageSize: 24,
      });
      this.RecommendedBrand = data.data.data;
      // console.log(data.data.data, "推荐品牌");
    },
    //获取首页店铺
    async getStoreLIst() {
      const data = await this.$API.homePage.storeLIst({
        object: {
          enterpriseId: "",
          location: "5",
        },
        pageNum: 1,
        pageSize: 10,
      });
      this.StoreLIst = data.data.data;
      // console.log(data.data.data, "推荐店铺");
    },
    //获取分类
    async getClassification() {
      const data = await this.$API.class.Classification({
        object: {
          enterpriseId: "",
        },
      });
      this.Classification = data.data.data.categoriesCatalog;
    },
    //获取新闻列表
    async getNews() {
      const data = await this.$API.class.News({
        object: {},
        pageNum: 1,
        pageSize: 6,
      });
      this.News = data.data.data;
      // if (this.News === []) {
      //   this.none = true;
      // }
    },
    //获取轮播图
    async getBanner() {
      const data = await this.$API.homePage.getBanner({
        object: {
          location: "1",
          storeId: "",
        },
        pageNum: 0,
        pageSize: 0,
      });
      this.Banner = data.data.data;

      // console.log(data.data.data[0].picPosition);
      // console.log(data.data.data, "推荐店铺");
    },
    //获取活动
    async getActivityList() {
      const data = await this.$API.homePage.getActivityList({
        object: {
          location: "1",
        },
        pageNum: 0,
        pageSize: 4,
      });
      this.Activity = data.data.data;
      this.ActivityPic = data.data.data.map((item) => {
        return {
          pic: item.platformPic.split(","),
        };
      });
    },

    // async getEnterpriseId() {
    //   const data = await this.$API.user.getEnterpriseId({
    //     creatorIdentityId: "",
    //     creatorUserId: "",
    //   });
    //   this.Id = data.data.data.id;
    // },
    // async getCurrUser() {
    //   const data = await this.$API.user.getCurrUser({
    //     object: {},
    //   });
    //   // console.log(data.data.data);
    //   this.User = data.data.data.userInfo;
    //   console.log(this.User);
    // },

    //跳转店铺首页
    goStore(id, enterpriseId) {
      this.$router.push({
        path: `Store/HomePage/${id}`,
        query: { enterpriseId: enterpriseId },
      });
    },
    //跳转推荐品牌
    goBrand(id, brandId, brandName) {
      this.$router.push({
        path: `AllBrand/RecommendedBrand/${id}/${brandId}`,
      });
    },
    //跳转活动
    goActivitiesColumn(id) {
      this.$router.push({ path: `ActivitiesColumn/${id}` });
    },
    //跳转分类
    goCategory(list) {
      let arr = [];
      this.isShow = !this.isShow;
      // console.log(list);
      if (list.rank === 1) {
        //点击一类
        arr.push(list.categoryId);
        for (let i = 0; i < list.subItemList.length; i++) {
          arr.push(list.subItemList[i].categoryId);
          if (list.subItemList[i].subItemList != null) {
            for (let n = 0; n < list.subItemList[i].subItemList.length; n++) {
              arr.push(list.subItemList[i].subItemList[n].categoryId);
            }
          }
        }
      } else if (list.rank === 2) {
        //二类
        arr.push(list.categoryId);
        if (list.subItemList != null) {
          for (let n = 0; n < list.subItemList.length; n++) {
            arr.push(list.subItemList[n].categoryId);
          }
        }
      } else {
        arr.push(list.categoryId);
      }
      this.$router.push({
        name: "category-list",
        query: {
          categoryId: JSON.stringify(arr),
        },
      });
      // this.$router.push({ path: `AllCtegories/CategoryList/${arr}` });
    },
    //跳转新闻
    goNewsDetails(newsCode) {
      this.$router.push({
        name: "news-details",
        params: {
          newsCode: newsCode,
        },
      });
    },
    showToggle(index) {
      if (this.$refs.sub.$children.length < 10) {
        document.getElementsByClassName("is-vertical")[0].style.display =
          "none";
      }
      this.$refs.sub.$el.style.width = "960px";
      this.$refs.sub.$el.style.boxShadow = "6px 0px 8px rgba(0, 0, 0, 0.18)";
      this.isShow = index;
    },
    handleHide() {
      this.isShow = !this.isShow;
      this.$refs.sub.$el.style.width = "200px";
      this.$refs.sub.$el.style.boxShadow = "none";
    },
    MouseoverBrand(index) {
      this.BrandShow = index;
    },
    //退出登录
    logOut() {
      Cookies.remove("token_personal");
      window.location.replace(this.$URL);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/home.scss";
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
</style>
<style>
#recommended .content .goods-box:nth-child(5n) {
  border-right: none;
}
</style>
